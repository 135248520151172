<template lang="pug">
trainer(
  :ids="ids",
  v-if="ready",
  v-on:toggleSort="toggleSort"
)
v-container(
  fill-height,
  fluid,
  v-else
)
  v-layout(
    align-center,
    justify-center
  )
    v-flex(
      shrink
    )
      v-progress-circular(
        color="primary",
        indeterminate,
        size="72",
        width="8"
      )
</template>

<script>
export default {
  components: {
    trainer: () =>
      import(/* webpackChunkName: "flashcard" */ `@/components/Trainer`),
  },
  data: () => ({
    ready: false,
    ids: [],
  }),
  created() {
    this.loadVocab()
  },
  methods: {
    async loadVocab() {
      const { rows } = await this.$bucket.db.allDocs({
        startkey: `00001:`,
        endkey: `99999:\uffff`,
      })
      this.start(rows.map(row => row.id))
    },
    start(ids) {
      this.ids.push(...ids)
      this.selecting = false
      this.ready = true
    },
    toggleSort(sorted) {
      if (sorted) this.ids = this.ids.sort()
      else this.ids = this.shuffle(this.ids)
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--
        ;[array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ]
      }

      return array
    },
  },
}
</script>
